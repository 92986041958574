<style lang="scss" scoped>
</style>
<template>
  <el-dialog title="編輯時間" v-model="state.dialogFormVisible" width="800px">
    <el-form
      :model="state.form"
      :ref="(el) => (state.addFrom = el)"
      :rules="state.addRules"
    >
      <div class="flex_line_c">
        <el-row class="width_100">
          <el-col :span="24">
            <el-form-item
              label="選擇時間"
              :label-width="state.formLabelWidth"
              prop="name"
              class="width_100"
            >
              <el-time-picker
                v-model="state.form.name"
                placeholder="選擇時間"
                :clearable="false"
              >
              </el-time-picker>

              <!-- <el-time-select
                v-model="state.form.name"
                start="00:00"
                step="00:30"
                end="23:59"
                placeholder="選擇時間"
              >
              </el-time-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="狀態"
              prop="status"
              class="width_100"
              :label-width="state.formLabelWidth"
            >
              <el-select placeholder="請選擇" v-model="state.form.region">
                <el-option label="正常" value="1"></el-option>
                <el-option label="禁用" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, watch, getCurrentInstance } from "vue";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: "",
    },
    timeDate: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量

    // 表单验证

    // state
    const state = reactive({
      radio: "1", // 選擇分類單選
      dialogFormVisible: props.dialogVisible,
      form: {
        // 表单数据
        name: "",
        region: "1",
      },
      addFrom: "", // 表单元素
      formLabelWidth: "80px", // label的宽度
      addRules: {
        // 表单正则
        name: [{ required: true, message: "請輸入分類名稱", trigger: "blur" }],
      },
      id: "",
    });

    // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) state.dialogFormVisible = val;
      }
    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    // 生命周期
    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $http } = proxy;

    // methods
    const init = (data) => {
      // $set(state.form,'name',data.time);
      state.form.name = new Date("2020/4/2" + " " + data.time);
      state.form.region = data.status;
      state.id = data.id;
    };
    const add_submit = () => {
      // 点击确定按钮
      if (!state.form.name) return $message.error("請選擇時間");
      state.addFrom.validate(async (valid) => {
        if (!valid) return;
        const res = await $http.handleBookingTime({
          id: state.id,
          time:
            new Date(state.form.name).getHours() +
            ":" +
            new Date(state.form.name).getMinutes(),
          status: state.form.region,
        });
        if (res.status !== 200) return;
        $message.success("新增成功");
        emit("reloadData", state.form);
        state.dialogFormVisible = false;
      });
    };

    return { state, add_submit, props, init };
  },
};
</script>
